<template>
    <form class="mb-3 mt-3" style="text-align: left;">
        <div class="card mb-3 mt-3">
            <div class="card-body">
                <h4 class="card-title">Need to return your item?</h4>
                <p>
                    <b>
                        If your item has developed a fault or you've just changed your mind,
                        please follow the steps below to return your item to us.
                    </b>
                </p>
                <h5>Find your Order</h5>
                <div class="mb-3">
                    <label for="orderNumberInput" class="form-label">Enter your order number below</label>
                    <input type="text" class="form-control" id="orderNumberInput" placeholder="Order number (e.g. 123456)"
                        :class="{
                            'is-invalid': valmsg.orderNumber && editedOrderNumber,
                            'is-valid': !valmsg.orderNumber && editedOrderNumber
                        }"
                        v-model="orderNumber">
                    <span class="invalid" v-if="valmsg.orderNumber">{{ valmsg.orderNumber }}</span>
                </div>
                <div class="mb-3">
                    <label for="customerEmailInput" class="form-label">Enter your email address below</label>
                    <input type="email" class="form-control" id="customerEmailInput" placeholder="Email Address"
                        :class="{
                            'is-invalid': valmsg.email && editedEmail,
                            'is-valid': !valmsg.email && editedEmail
                        }"
                        v-model="customerEmail">
                    <span class="invalid" v-if="valmsg.email">{{ valmsg.email }}</span>
                </div>
                <div class="mb-4">
                    <label for="customerPostCodeInput" class="form-label">Enter the postcode that the item was delivered to below</label>
                    <input type="text" class="form-control" id="customerPostCodeInput" placeholder="Postcode"
                        :class="{
                            'is-invalid': valmsg.postcode && editedPostcode,
                            'is-valid': !valmsg.postcode && editedPostcode
                        }"
                        v-model="customerPostCode">
                    <span class="invalid" v-if="valmsg.postcode">{{ valmsg.postcode }}</span>
                </div>
                <div class="mb-2">
                    <button type="button" class="btn btn-primary mb-2"
                        @click="getOrderItems" :disabled="!areDetailsValid || getOrderItemsLoading">
                        <span v-if="getOrderItemsLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Find order
                    </button>
                    <div v-if="orderFormErrorMsg">
                        <span class="invalid"><b>{{ orderFormErrorMsg }}</b></span>
                    </div>
                </div>
            </div>
        </div>
        <Transition name="fade">
            <div v-if="orderItems!= null && orderItems.length > 0" class="card mb-3" id="div-orderitems">
                <div class="card-body">
                    <h5 class="card-title">Items for order {{ lastValidOrderNumber }}</h5>
                    <h6 class="card-title">Select the item you wish to return</h6>
                    <div class="card mb-3 g-0" v-for="item in orderItems" :key="item.raspOrderItemID"
                        :class="((selectedReturnItem != null && selectedReturnItem.raspOrderItemID == item.raspOrderItemID) ? 'selected' : '')">
                        <label :for="'radio-'+item.raspOrderItemID">
                            <div class="card-body">
                                <input class="form-check-input" type="radio" :id="'radio-'+item.raspOrderItemID"
                                    v-model="selectedReturnItem" :value="item" @change="scrollToReturnReasons"
                                    :disabled="item.returnItemMessage != null || requestSubmitSuccess">
                                <div class="product-name">
                                    {{ item.productName }}
                                    <small v-if="item.reference" class="text-muted">{{ item.reference }}</small>
                                </div>
                                <span v-if="item.returnItemMessage" class="col-sm-12 invalid" >{{ item.returnItemMessage }}</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </Transition>      
        <Transition name="fade">
            <div class="card mb-3" id="div-returnreasons" v-if="returnReasons.length > 0 && returnItemSelected">
                <div class="card-body">
                    <h4 class="card-title">Reason for Return</h4>
                    <div class="dropdown mb-3">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="returnreason-dropdown"
                            data-bs-toggle="dropdown" aria-expanded="false" :disabled="requestSubmitSuccess">
                            <p v-if="selectedReturnReason == null">Select a reason</p>
                            <p v-else>{{ selectedReturnReason.websiteText }}</p>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="returnreason-dropdown">
                            <li v-for="returnreason in returnReasons" :key="returnreason.returnReasonId">
                                <a class="dropdown-item" @click="changeReturnReason(returnreason)"
                                    href="javascript:void(0)">{{ returnreason.websiteText }}</a>
                            </li>
                        </ul>
                    </div>
                    <Transition name="fade" appear>
                        <div class="mb-3" v-if="returnReasonSelected">
                            <label for="additionalReturnReasonBox" class="form-label">
                                <h5>Additional information</h5>
                            </label>
                            <p v-if="returnReasonFaulty">Please provide us with a description of the fault</p>
                            <textarea id="additionalReturnReasonBox" rows="3" class="form-control mb-3" placeholder="Please provide more details"
                                v-model="customerReturnText" :disabled="disableResolutionSection"></textarea>                  
                            <h5 class="card-title">Resolution</h5>
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="return-resolution-dropdown" 
                                data-bs-toggle="dropdown" aria-expanded="false" :disabled="disableResolutionSection">
                                <p v-if="selectedResolution == null">Please select a resolution</p>
                                <p v-else>{{ selectedResolution.text }}</p>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="return-resolution-dropdown">
                                <li v-for="res in returnResolutionOptions" :key="res.value">
                                    <a href="javascript:void(0)" class="dropdown-item"
                                        @click= "selectedResolution = res">{{ res.text }}</a>
                                </li>
                            </ul>
                        </div>
                    </Transition>
                    
                    <div class="mb-3" v-if="showReturnsPolicyWarning || showWarrantyPeriodWarning">
                        <div v-if="showWarrantyPeriodWarning">
                            <b>Sorry, the item is outside of our {{ selectedReturnItem?.warrantyMonths }} month warranty period.</b>
                        </div>
                        <div v-if="showReturnsPolicyWarning && !showWarrantyPeriodWarning">
                            <b>Sorry, the item is outside of our {{ selectedReturnItem?.returnsPolicyDays }} day returns policy.</b>
                        </div>
                        <div>
                            If you would like further information, please see our <a :href="termsAndConditionsLink">terms & conditions</a>. 
                            If you have any more questions please contact us at <a :href="emailLink">{{ emailLink?.split(':')[1] }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <Transition name="fade">
            <div class="mb-3" v-if="returnReasonSelected && returnItemSelected">
                <button type="button" class="btn btn-primary" @click="submitReturnRequest" :disabled="disableSubmitReturnRequest">
                    Submit Return Request
                </button>
                <div v-if="submitErrorMsg"><span class="invalid">{{ submitErrorMsg }}</span></div>
            </div>
        </Transition>
        <Transition name="fade">
            <div v-if="requestSubmitSuccess" id="submitsuccess" class="card mb-4">
                <div class="container">
                    <div class="row mb-2">
                        <div class="col-sm-12 alert alert-success" role="alert">
                            Thank you! Now follow these instructions on how to return your item to us:
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <h5>Step 1</h5>
                            <p>
                                Please place your item along with any or all accessories back in the original box, you can wrap the whole box in bubble packaging for added protection. 
                                If you no longer have the box please ensure the item is placed in a secure rigid container to prevent any movement in the post.
                            </p>
                            <p v-if="!isConfigCC">
                                If your item has any manufacturer account locks and or passwords please ensure these are removed prior to returning your item, for ease of your return we have linked instructions from the most common manufacturer account locks below,
                                should the item you have received have an account lock that you are unable to remove please call us on 0330 120 1541 prior to posting your item.
                                <ul>
                                    <li><a href="https://support.apple.com/en-us/HT208855">Unlink your Apple product from your iCloud</a></li>
                                    <li><a href="https://support.google.com/android/answer/7664951?hl=en">Unlink your product from your Google account</a></li>
                                    <li><a href="https://support.ring.com/hc/en-gb/articles/360038010351-How-to-take-over-or-change-ownership-of-a-Ring-device">Unlink your item from your Ring Door account</a></li>
                                    <li><a href="https://support.google.com/googlenest/answer/9691327?hl=en-GB">Unlink your item from your Google Nest account</a></li>
                                    <li><a href="https://www.philips-hue.com/en-gb/support/faq/apps-and-software#How_do_I_remove_an_unused_smart_device_from_my_Philips_Hue_system">Unlink your item from your Philips Hue account</a></li>
                                </ul>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <h5>Step 2</h5>
                            <div v-if="showRoyalMailPortal">
                                <div class="mb-3">
                                    <img src="../assets/royalmail_negative.png" alt="Royal Mail Logo">
                                    <p>Return through your local Post Office branch or Royal Mail Customer Service Point in over 12,000 locations. Open 7 days a week, early until late.</p>
                                </div>
                                <div>
                                    <a :href="config?.rmPortalLink" target="_blank" class="btn btn-primary" role="button">Create Return Label</a>
                                </div>
                            </div>
                            <div v-else>
                                <p>
                                    Please send your device to the following address including your order number:
                                    <b>
                                        <br>{{ lastValidOrderNumber }}
                                        <br>{{ config?.siteFullName }}
                                        <br>Kingswood Lakeside
                                        <br>Blakeney Way
                                        <br>Kingswood Lakeside
                                        <br>Cannock
                                        <br>WS11 8JD
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </form>
</template>

<style scoped lang="scss" src="../assets/scss/ReturnRequestForm.scss"></style>

<script lang="ts">
    import { defineComponent } from 'vue';
    
    const emailRegEx: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const orderNumberRegex: RegExp = /^(\d{5,8})$/;
    const postcodeRegex: RegExp = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/;
    
    const scrollToElWithId = (id: string) => {
        const el = document.getElementById(id);
        if (el) { el.scrollIntoView({ behavior: "smooth" }); }
    }

    interface Dictionary<T> {
        [Key: string]: T;
    }

    interface OrderItem {
        raspOrderID: number,
        caOrderID: string,
        raspOrderItemID: number,
        caOrderItemID: number,
        productImageURL: string,
        productName: string,
        reference: string,
        returnsPolicyDays: number,
        returnsPolicyExpiryDate: Date,
        warrantyMonths: number,
        warrantyExpiryDate: Date,
        returnRequestStatusId: null | string,
        returnReasonId: string,
        returnResolutionTypeId: string,
        returnItemMessage: string
    }

    interface Data {
        orderNumber: string,
        customerEmail: string,
        customerPostCode: string,
        lastValidOrderNumber: string,
        valmsg: Dictionary<string>,
        orderItems: Array<OrderItem>,
        selectedReturnItem: null | OrderItem,
        getOrderItemsLoading: boolean,
        apiSpecialNumber: number,
        returnReasons: Array<ReturnReason>,
        selectedReturnReason: null | ReturnReason,
        customerReturnText: string,
        customerID: number,
        returnResolutionOptions: Array<ReturnResolution>,
        selectedResolution: null | ReturnResolution,
        showReturnResolutionOptions: boolean,
        requestSubmitSuccess: boolean,
        disableSubmitForm: boolean,
        config: Record<string, any> | undefined,
        emailLink: string,
        termsAndConditionsLink: string,
        isConfigCC: boolean,
        orderFormErrorMsg: null | string,
        submitErrorMsg: null | string,
        editedOrderNumber: boolean,
        editedEmail: boolean,
        editedPostcode: boolean
    }

    interface ReturnResolution {
        class: string,
        value: string,
        text: string
    }

    interface ReturnReason {
        returnReasonId: string,
        name: string,
        customerReason: boolean,
        websiteText: string
    }

    interface OrderItemSearchResults {
        orderItems: Array<OrderItem>,
        success: boolean,
        orderID: number,
        customerID: number,
        processMessage: string,
    }

    export default defineComponent ({
        props: {
            configProp: Object,
            isConfigCCProp: Boolean
        },
        data(): Data {
            return {
                orderNumber: "",
                customerEmail: "",
                customerPostCode: "",
                lastValidOrderNumber: "",
                valmsg: {},
                orderItems: [],
                selectedReturnItem: null,
                getOrderItemsLoading: false,
                apiSpecialNumber: 0,
                returnReasons: [],
                selectedReturnReason: null,
                customerReturnText: "",
                customerID: -1,
                returnResolutionOptions: [],
                selectedResolution: null,
                showReturnResolutionOptions: false,
                requestSubmitSuccess: false,
                disableSubmitForm: false,
                config: this.configProp,
                emailLink: "",
                termsAndConditionsLink: "",
                isConfigCC: false,
                orderFormErrorMsg: null,
                submitErrorMsg: null,
                editedOrderNumber: false,
                editedEmail: false,
                editedPostcode: false
            };
        },
        computed: {
            areDetailsValid(): boolean {
                if (!this.orderNumber || !this.customerEmail || !this.customerPostCode) {
                    return false;
                }
                
                for (var key in this.valmsg) {
                    if (this.valmsg[key]) {
                        return false;
                    }
                }
                return true;
            },
            disableSubmitReturnRequest(): boolean {
                return !this.areDetailsValid 
                    || !this.returnItemSelected
                    || !this.returnReasonSelected
                    || this.selectedResolution == null
                    || this.showReturnsPolicyWarning
                    || this.showWarrantyPeriodWarning
                    || this.requestSubmitSuccess;
            },
            returnItemSelected(): boolean {
                return this.selectedReturnItem != null;
            },
            returnReasonSelected(): boolean {
                return this.selectedReturnReason != null;
            },
            returnReasonFaulty(): boolean {
                return this.returnReasonSelected && this.selectedReturnReason?.returnReasonId == "FAULTY";
            },
            selectedItemInWarranty(): boolean {
                return this.returnItemSelected
                    && this.selectedReturnItem?.warrantyExpiryDate != null
                    && new Date(this.selectedReturnItem.warrantyExpiryDate) > new Date();
            },
            selectedItemInReturnsPeriod(): boolean {
                return this.returnItemSelected
                    && this.selectedReturnItem?.returnsPolicyExpiryDate != null
                    && new Date(this.selectedReturnItem.returnsPolicyExpiryDate) > new Date();
            },
            showWarrantyPeriodWarning(): boolean {
                return this.returnItemSelected && this.returnReasonSelected && !this.selectedItemInWarranty;
            },
            showReturnsPolicyWarning(): boolean {
                return this.returnItemSelected && this.returnReasonSelected && !this.returnReasonFaulty && !this.selectedItemInReturnsPeriod;
            },
            showRoyalMailPortal(): boolean {
                return this.returnItemSelected && this.selectedItemInReturnsPeriod && this.returnReasonFaulty && this.selectedItemInWarranty;
            },
            disableResolutionSection(): boolean {
                return this.requestSubmitSuccess || this.showWarrantyPeriodWarning || this.showReturnsPolicyWarning;
            }
        },
        created() {
            this.isConfigCC = this.isConfigCCProp;
            this.emailLink = this.configProp?.socialLinks.Email;
            this.termsAndConditionsLink = this.configProp?.termsAndConditionsLink;
        },
        watch: {
            customerEmail: function(value) {
                this.editedEmail = true;
                if (emailRegEx.test(value.trim())) {
                    this.valmsg['email'] = '';
                    this.customerEmail = value.trim();
                    return;
                }
                this.valmsg['email'] = 'Invalid Email Address';
            },
            orderNumber: function(value) {
                this.editedOrderNumber = true;
                if (orderNumberRegex.test(value.trim())) {
                    this.valmsg['orderNumber'] = '';
                    this.orderNumber = value.trim();
                    return;
                }
                this.valmsg['orderNumber'] = 'Invalid Order number';
            },
            customerPostCode: function(value) {
                this.editedPostcode = true;
                if (postcodeRegex.test(value.trim())) {
                    this.valmsg['postcode'] = '';
                    this.customerPostCode = value.trim();
                    return;
                }
                this.valmsg['postcode'] = 'Invalid PostCode';
            }
        },
        methods: {
            scrollToOrderItems() {
                scrollToElWithId('div-orderitems');
            },
            scrollToReturnReasons() {
                scrollToElWithId('div-returnreasons');
            },
            scrollToReturnResolution() {
                scrollToElWithId('return-resolution-dropdown');
            },
            scrollToSubmitSuccess() {
                scrollToElWithId('submitsuccess');
            },
            populateReturnResolutionOptions()
            {
                this.returnResolutionOptions = [
                    { class: "refund", value:"REFUND", text:"I would like a refund" },
                    { class: "replace", value:"REPLACE", text:"I would like to repair/replace the item" }
                ];
            },
            changeReturnReason(returnReason: ReturnReason) {
                this.selectedResolution = null;
                this.populateReturnResolutionOptions();
                this.selectedReturnReason = returnReason;

                if (this.selectedReturnItem == null)
                {
                    return;
                }

                if (this.returnReasonFaulty && this.selectedItemInWarranty && !this.selectedItemInReturnsPeriod)
                {
                    const refundIndex = this.returnResolutionOptions.findIndex(res => res.value == "REFUND");
                    if(refundIndex != -1)
                    {
                        this.returnResolutionOptions.splice(refundIndex, 1);
                    }
                }

                setTimeout( () => this.scrollToReturnResolution(), 500);
            },
            getOrderItemsActual() {
                this.orderFormErrorMsg = null;
                this.orderItems = [];
                this.selectedResolution = null;
                this.selectedReturnReason = null;
                this.requestSubmitSuccess = false;
                this.lastValidOrderNumber = this.orderNumber.trim();
                this.getOrderItemsLoading = true;
                this.customerReturnText = "";

                //OrderItemSearchRequest
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        orderID: this.lastValidOrderNumber,
                        customerEmail: this.customerEmail,
                        customerPostCode: this.customerPostCode
                    })
                };

                fetch('api/orderitem/getorderitems', requestOptions)
                    .then(async response => {
                        this.getOrderItemsLoading = false;
                        const data: OrderItemSearchResults = await response.json();

                        if (!response.ok) {
                            this.orderFormErrorMsg = `Unable to retrieve Order Items (${response.status}).`;
                            return;
                        }

                        if (!data.success) {
                            this.orderFormErrorMsg = data.processMessage;
                            return;
                        }

                        this.orderItems = data.orderItems;
                        this.customerID = data.customerID;
                        this.requestSubmitSuccess = false;
                    });
            },
            submitReturnRequest() {
                this.submitErrorMsg = null;

                if (!this.returnItemSelected || !this.returnReasonSelected || this.selectedResolution == null) {
                    this.submitErrorMsg = "Items or options not selected";
                    return;
                }

                const returnRequestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        // ReturnRequest
                        OrderItemId: this.selectedReturnItem?.raspOrderItemID,
                        CustomerId: this.customerID,
                        ReturnOptionId: "CUSTRET", // "COURIER"/"SPECDEV" //TODO: Royal Mail option here??
                        ReturnReasonId: this.selectedReturnReason?.returnReasonId,
                        Note: this.customerReturnText.trim(),
                        ReturnResolutionTypeId: this.selectedResolution?.value
                    })
                };
                fetch('api/returns/returnrequest', returnRequestOptions)
                    .then(async response => {
                        const data = await response.json(); // standard Result

                        if (!response.ok) {
                            const error = (data && data.processMessage) || response.status;
                            this.submitErrorMsg = `Error Submitting Return Request: ${response.status}`;
                            return Promise.reject(error); // need error toasts
                        }

                        if (data.success != true) {
                            this.submitErrorMsg = `Error Submitting Return Request: ${data.processMessage}`;
                            return Promise.reject(data.processMessage);
                        }

                        this.requestSubmitSuccess = true;
                        this.disableSubmitForm = true;
                        setTimeout( () => this.scrollToSubmitSuccess(), 500);
                    });
            },
            getReturnReasons() {
                this.returnReasons = [];

                fetch('api/returns/getreturnreasons')
                    .then(r => r.json())
                    .then(json => {
                        this.returnReasons = json;
                        return;
                    });
            },          
            getOrderItems(): void {
                this.getReturnReasons();
                this.getOrderItemsActual();
            }
        },
    });
</script>